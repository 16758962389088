@import "./reset.css";

:root {
  --color-transparent-hover: rgb(0 0 0 / 0.02);
  --color-shadow: 168 179 200;

  --color-background: 255 255 255;
  --color-background-alt: 243 246 249;
  --color-background-alt2: 23 92 219;
  --color-background-alt3: 26 65 172;
  --color-background-alt4: 2 15 102;

  --color-primary-100: 219 229 246;
  --color-primary-300: 121 161 233;
  --color-primary-600: 23 93 220;
  --color-primary-700: 26 65 172;

  --color-secondary-100: 230 233 239;
  --color-secondary-300: 168 179 200;
  --color-secondary-500: 90 109 145;
  --color-secondary-600: 83 99 131;
  --color-secondary-700: 63 75 99;

  --color-info-100: 219 229 246;
  --color-info-600: 121 161 233;
  --color-info-700: 26 65 172;

  --color-warning-100: 255 248 228;
  --color-warning-600: 255 191 0;
  --color-warning-700: 172 88 0;

  --color-danger-100: 255 236 239;
  --color-danger-600: 203 38 58;
  --color-danger-700: 149 27 42;

  --color-success-100: 191 236 195;
  --color-success-600: 12 128 24;
  --color-success-700: 11 111 21;

  --color-notification-100: 255 225 247;
  --color-notification-600: 192 17 118;

  --color-art-primary: 2 15 102;
  --color-art-accent: 44 221 223;

  --color-text-main: 27 32 41;
  --color-text-muted: 96 109 145;
  --color-text-contrast: 255 255 255;
  --color-text-alt2: 255 255 255;
  --color-text-code: 192 17 118;

  --color-marketing-logo: 23 93 220;

  --tw-ring-offset-color: #ffffff;
}

.theme_light {
  /* should be left empty as white is the default */
}

.theme_dark {
  --color-transparent-hover: rgb(255 255 255 / 0.02);
  --color-shadow: 0 0 0;

  --color-background: 32 39 51;
  --color-background-alt: 18 26 39;
  --color-background-alt2: 47 52 61;
  --color-background-alt3: 48 57 70;
  --color-background-alt4: 18 26 39;

  --color-primary-100: 26 39 78;
  --color-primary-300: 26 65 172;
  --color-primary-600: 101 171 255;
  --color-primary-700: 170 195 239;

  --color-secondary-100: 48 57 70;
  --color-secondary-300: 82 91 106;
  --color-secondary-500: 121 128 142;
  --color-secondary-600: 143 152 166;
  --color-secondary-700: 158 167 181;

  --color-success-100: 11 111 21;
  --color-success-600: 107 241 120;
  --color-success-700: 191 236 195;

  --color-danger-100: 149 27 42;
  --color-danger-600: 255 78 99;
  --color-danger-700: 255 236 239;

  --color-warning-100: 172 88 0;
  --color-warning-600: 255 191 0;
  --color-warning-700: 255 248 228;

  --color-info-100: 26 65 172;
  --color-info-600: 121 161 233;
  --color-info-700: 219 229 246;

  --color-notification-100: 117 37 83;
  --color-notification-600: 255 143 208;

  --color-art-primary: 243 246 249;
  --color-art-accent: 44 221 233;

  --color-text-main: 243 246 249;
  --color-text-muted: 136 152 181;
  --color-text-contrast: 18 26 39;
  --color-text-alt2: 255 255 255;
  --color-text-code: 255 143 208;

  --color-marketing-logo: 255 255 255;

  --tw-ring-offset-color: #1f242e;
}

.theme_nord {
  --color-transparent-hover: rgb(255 255 255 / 0.12);

  --color-background: 67 76 94;
  --color-background-alt: 59 66 82;
  --color-background-alt2: 76 86 106;
  --color-background-alt3: 76 86 106;
  --color-background-alt4: 67 76 94;

  --color-primary-300: 108 153 166;
  --color-primary-600: 136 192 208;
  --color-primary-700: 160 224 242;

  --color-secondary-100: 76 86 106;
  --color-secondary-300: 94 105 125;
  --color-secondary-600: 216 222 233;
  --color-secondary-700: 255 255 255;

  --color-success-600: 163 190 140;
  --color-success-700: 144 170 122;

  --color-danger-600: 228 129 139;
  --color-danger-700: 191 97 106;

  --color-warning-600: 235 203 139;
  --color-warning-700: 210 181 121;

  --color-info-600: 129 161 193;
  --color-info-700: 94 129 172;

  --color-text-main: 229 233 240;
  --color-text-muted: 216 222 233;
  --color-text-contrast: 46 52 64;
  --color-text-alt2: 255 255 255;
  --color-text-code: 219 177 211;

  --color-marketing-logo: 255 255 255;

  --tw-ring-offset-color: #434c5e;
}

.theme_solarized {
  --color-transparent-hover: rgb(255 255 255 / 0.12);

  --color-background: 0 43 54;
  --color-background-alt: 7 54 66;
  --color-background-alt2: 31 72 87;
  --color-background-alt3: 31 72 87;
  --color-background-alt4: 0 43 54;

  --color-primary-300: 42 161 152;
  --color-primary-600: 133 153 0;
  --color-primary-700: 192 203 123;

  --color-secondary-100: 31 72 87;
  --color-secondary-300: 101 123 131;
  --color-secondary-600: 131 148 150;
  --color-secondary-700: 238 232 213;

  --color-success-600: 133 153 0;
  --color-success-700: 192 203 123;

  --color-danger-600: 220 50 47;
  --color-danger-700: 223 135 134;

  --color-warning-600: 181 137 0;
  --color-warning-700: 220 189 92;

  --color-info-600: 133 153 0;
  --color-info-700: 192 203 123;

  --color-text-main: 253 246 227;
  --color-text-muted: 147 161 161;
  --color-text-contrast: 0 0 0;
  --color-text-alt2: 255 255 255;
  --color-text-code: 240 141 199;

  --color-marketing-logo: 255 255 255;

  --tw-ring-offset-color: #002b36;
}

@import "./popover/popover.component.css";
@import "./search/search.component.css";

@import "./toast/toast.tokens.css";
@import "./toast/toastr.css";

/**
 * tw-break-words does not work with table cells:
 * https://github.com/tailwindlabs/tailwindcss/issues/835
 */
td.tw-break-words {
  overflow-wrap: anywhere;
}

/**
 * tw-list-none hides summary arrow in Firefox & Chrome but not Safari:
 * https://github.com/tailwindlabs/tailwindcss/issues/924#issuecomment-915509785
 */
summary.tw-list-none::marker,
summary.tw-list-none::-webkit-details-marker {
  display: none;
}

/**
 * Arbitrary values can't be used with `text-align`:
 * https://github.com/tailwindlabs/tailwindcss/issues/802#issuecomment-849013311
 */
.tw-text-unset {
  text-align: unset;
}

/**
 * Bootstrap uses z-index: 1050 for modals, dialogs and drag-and-drop previews should appear above them.
 * When bootstrap is removed, test if these styles are still needed and that overlays display properly over other content.
 * CL-483
 */
.cdk-drag-preview,
.cdk-overlay-container,
.cdk-global-overlay-wrapper,
.cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop,
.cdk-overlay-pane {
  z-index: 2000 !important;
}

.cdk-global-scrollblock {
  position: relative;
  height: 100%;
  overflow: hidden;
}
